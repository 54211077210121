<template>
  <div
    class="banner banner-image overflow-hidden bg-white relative"
    :class="{ 'banner--border': showBorder }"
  >
    <div class="banner__image h-full w-full relative">
      <img
        :sizes="constants.images.sizes.banner"
        :src="src"
        :srcset="srcset"
        :alt="alt"
        class="w-full h-full object-cover fade-up fade-out animation-delay-100"
        data-rellax-speed="-4"
        ref="bannerImage"
      />
      <div
        class="overlay inset-0 absolute fade-up fade-out animation-delay-100"
      ></div>
      <OverlayPattern
        blend="exclusion"
        :pulseOrder="1"
        :opacity="grid.lg ? '0.4' : '0.6'"
        class="banner__image__pattern"
      />
      <OverlayPattern
        blend="screen"
        :pulseOrder="2"
        :opacity="grid.lg ? '0.4' : '0.6'"
        :desaturate="true"
        class="banner__image__pattern"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import OverlayPattern from "@/components/OverlayPattern.vue";
import { useParallax } from "@/composables/useParallax";
import { CONSTANTS } from "@/util/constants";

export default {
  props: {
    banner: {
      type: [Object, Boolean],
      required: true,
    },
  },
  components: {
    OverlayPattern,
  },
  async setup({ banner }) {
    const { createParallax, destroyParallax } = useParallax();
    const bannerImage = ref();

    onMounted(() => {
      createParallax([bannerImage]);
    });

    onBeforeUnmount(() => {
      destroyParallax();
    });

    return {
      bannerImage,
      srcset: banner.srcset || CONSTANTS.images.fallback.banner.srcset,
      src: banner.sizes?.large || CONSTANTS.images.fallback.banner.src,
      alt: banner.alt || "",
      constants: CONSTANTS,
    };
  },
};
</script>

<style scoped lang="scss">
.banner {
  &__image {
    &:before {
      @include loading-spinner-pseudo;
    }
    img {
    }
  }
  .overlay {
    @include overlay-image;
  }
  &--border {
    border-width: 1rem;
    border-color: white;
    border-style: solid solid none;
  }
}
</style>
