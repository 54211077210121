<!-- eslint-disable max-len -->
<template>
  <div
    :class="{ 'view-leaving': viewLeaving }"
    v-if="!viewLeavingAnimationComplete"
  >
    <client-only>
      <Lightgallery />
    </client-only>
    <div class="page index-page" v-if="pageData">
      <ImageBanner :banner="pageData.media.image_banner" />
      <FullScreenVideo :video="pageData.media.video" ref="fullScreenVideoRef" />
      <div class="primary relative">
        <MediaActions>
          <button
            v-if="!grid.md && pageData.media.video.id"
            class="btn btn-default btn-icon btn-inverse"
            @click="openVideoPlayerWithRefs"
          >
            <i class="icon icon-videocamera" />
            Play Video
          </button>
        </MediaActions>
        <VideoBox
          v-if="pageData.media.video.id"
          :video="pageData.media.video"
          @video-button-click="openVideoPlayerWithRefs"
        />
        <div class="content-index padding-app z-20 relative bg-white">
          <PageHeader
            :heading="pageData.title_display"
            :subheading="pageData.subtitle"
          />

          <div class="main-content fade-up">
            <Excerpt
              :text="pageData.description"
              :pageType="'index'"
              class="pt-12 mb-12"
            />
            <template v-if="hasPartialSlot">
              <slot name="partial" :pageData="pageData"></slot>
            </template>
            <londo-swipe-carousel
              v-if="cards && showCarousel"
              :visible="5"
              :cards="cards"
              :routeScope="mapIndexPageIdToSinglePageRoute"
              :heading="pageData.cards_heading"
              :flipped="isCompare"
              :gridInitial="isCompare"
              :layoutSwitcher="true"
            />
            <slot name="bottom-buttons"></slot>
            <RenderHtml
              v-if="pageData.contents"
              :contents="pageData.contents"
            />
            <template v-if="hasAfterContentPartialSlot">
              <slot name="afterContentPartial" :pageData="pageData"></slot>
            </template>
          </div>
        </div>
        <div
          class="page__bottom-nav padding-app mt-12 fade-up"
          v-if="nextIndexPage"
        >
          <div class="width-md-sm flex">
            <NuxtLink
              :to="nextIndexPage.route"
              v-if="!nextIndexPage.external"
              class="btn btn-nav btn-next ml-auto"
            >
              <span>{{ nextIndexPage.text }}</span>
            </NuxtLink>
            <a
              v-if="nextIndexPage.external"
              :href="nextIndexPage.external"
              target="_blank"
              rel="noreferrer"
              class="btn btn-nav btn-next ml-auto"
            >
              <span>{{ nextIndexPage.text }}</span>
            </a>
          </div>
        </div>
        <ClientOnly>
          <BottomVideo
            v-if="pageData?.media.video_background"
            :preloadVideo="false"
            :videoId="pageData.media.video_background"
            @vimeo-error="pageData.media.video_background = false"
          />
          <BottomImage v-else :image="pageData.media.image_bottom" />
        </ClientOnly>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import pageMappings, { pageToPageId } from "@/util/pageMapping";
import { pageIdMapping, indexPageIdToSinglePage } from "@/api/api";
import store from "@/store";
import RenderHtml from "@/components/Utilities/RenderHtml.vue";
import PageHeader from "@/components/PageHeader.vue";
import Excerpt from "@/components/Excerpt.vue";
import ImageBanner from "@/components/ImageBanner.vue";
import Lightgallery from "@/components/Lightgallery.vue";
import { useViewTransitionFlags } from "@/composables/useViewTransitionFlags";

import BottomVideo from "@/components/BottomVideo.vue";
import BottomImage from "@/components/BottomImage.vue";
import MediaActions from "@/components/MediaActions.vue";
import Footer from "@/components/TheFooter.vue";

import FullScreenVideo from "@/components/FullScreenVideo.vue";
import { useMenuItems } from "@/composables/useMenuItems";
import { useLightGalleryVideo } from "@/composables/useLightGalleryVideo";
import VideoBox from "@/components/VideoBox.vue";
import { CONSTANTS } from "@/util/constants";
const {
  defaultTitle,
  defaultDescription,
  defaultImage,
  londoloziSuffix,
  stripTagsReg,
} = CONSTANTS.seo;
import tracking from "@/mixins/tracking";
import scrollTracking from "@/mixins/scrollTracking";

export default {
  components: {
    RenderHtml,
    PageHeader,
    Excerpt,
    BottomVideo,
    BottomImage,
    Footer,
    FullScreenVideo,
    ImageBanner,
    MediaActions,
    VideoBox,
  },
  mixins: [tracking, scrollTracking],
  async setup(_, { slots }) {
    const viewLeaving = ref(false);
    const viewLeavingAnimationComplete = ref(false);

    const { togglePageDataReady, toggleViewLeaving } = useViewTransitionFlags(
      viewLeaving,
      viewLeavingAnimationComplete
    );

    const toggleDrawerState = () => {
      store.commit("toggleDrawerState");
    };

    onBeforeRouteLeave((to, from, next) => {
      toggleViewLeaving(["onBeforeRouteLeave"]);
      next();
    });

    const route = useRoute();
    const id = pageToPageId[route.name];
    const pageData = computed(() => store.getters.pageById(id));

    if (!pageData.value) {
      const { data } = await useFetch(`/api/index-page?name=${route.name}`);
      if (data?.value?.data && process.client) {
        store.dispatch("setPageData", {
          id,
          payload: data.value.data,
        });
        pageData.value = data.value.data;
        togglePageDataReady();
      }
    } else {
      togglePageDataReady();
    }

    const { menuItems } = useMenuItems();
    const { openVideoPlayer } = useLightGalleryVideo();

    const fullScreenVideoRef = ref(null);

    const mapIndexPageIdToSinglePageRoute = computed(
      () => indexPageIdToSinglePage[pageData.value.id]
    );
    const cards = computed(() => {
      if (isCompare.value) {
        return store.getters.childCardsIdsForIndexId(pageIdMapping.camps);
      }

      const renderableCards = store.getters.childCardsIdsForIndexId(
        pageData.value.id
      );

      if (renderableCards && renderableCards.length) {
        return renderableCards;
      }

      return null;
    });

    const hasPartialSlot = computed(() => !!slots.partial);
    const hasAfterContentPartialSlot = computed(
      () => !!slots.afterContentPartial
    );

    const showCarousel = computed(() => !pageData.value.connect);
    const nextIndexPage = computed(() => {
      const currentPageIndex = menuItems.value.findIndex(
        (x) => x.route === route.name.split("_")[0].split("-")[1]
      );
      return currentPageIndex !== -1
        ? menuItems.value[currentPageIndex + 1]
        : false;
    });

    const isCompare = computed(
      () => route.name === "en-accommodation-compare-camps___en"
    );

    const openVideoPlayerWithRefs = () => {
      if (fullScreenVideoRef.value) {
        openVideoPlayer(
          fullScreenVideoRef.value.videoGalleryRef,
          fullScreenVideoRef.value.triggerRef
        );
      }
    };

    const title = (
      pageData.value?.seo?.title ||
      pageData.value?.title_display ||
      pageData.value?.title ||
      defaultTitle
    );

    const description = (
      pageData.value?.seo?.description.replace(stripTagsReg, "") ||
      pageData.value?.description?.replace(stripTagsReg, "") ||
      defaultDescription
    );

    const image = (
      pageData.value?.seo?.image.url ||
      pageData.value?.media?.image_banner?.url ||
      defaultImage
    );

    const baseURL = typeof window === "undefined" ? null : (
      process.env.NODE_ENV === "development"
        ? `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        : `https://${window.location.host}${window.location.pathname}`
    );

    useSeoMeta({
      title: `${title} ${londoloziSuffix}`,
      description: description,
      ogTitle: `${title} ${londoloziSuffix}`,
      ogDescription: description,
      ogImage: image,
      twitterTitle: `${title} ${londoloziSuffix}`,
      twitterImage: image,
      lang: "en",
      canonical: `${baseURL}`,
      link: [{ rel: "canonical", href: `${baseURL}` }],
    });

    return {
      showCarousel,
      nextIndexPage,
      mapIndexPageIdToSinglePageRoute,
      cards,
      pageData,
      hasPartialSlot,
      hasAfterContentPartialSlot,
      route,
      pageMappings,
      fullScreenVideoRef,
      openVideoPlayerWithRefs,
      isCompare,
      viewLeaving,
      viewLeavingAnimationComplete,
    };
  },
};
</script>
